
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import axios from "axios";
import Markdown from "vue3-markdown-it";
import localforage from "localforage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "LearningPathSuggestionsShowView",
  components: { Markdown },
  setup() {
    const learningPathForm = ref();
    const contactDetails = ref({
      name: "",
      company: "",
      email: "",
      phone: "",
    });
    const router = useRouter();
    const markdownSource = ref("");
    const results = ref<HTMLElement | null>(null);
    const reachOutContainer = ref<HTMLElement | null>(null);
    const getLearningPathLoading = ref(false);
    const learningPathDetails = ref({}) as any;
    const learningPathRequestDetails = ref({});
    const updatedAt = ref();
    onMounted(() => {
      const hash = router.currentRoute.value.params.id as any;
      if (typeof hash === "string") {
        // Handle the string case
        callApiGetLearningPathDetails(hash);
      } else {
        // Handle the string[] case
        const firstHash = hash[0]; // Extract the first string from the array
        callApiGetLearningPathDetails(firstHash);
      }
    });
    const callApiGetLearningPathDetails = async (hash: string) => {
      try {
        getLearningPathLoading.value = true;
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/openai/learning-path/${hash}`
        );

        // Handle successful response
        learningPathDetails.value = response.data.payload.learningPath;
        learningPathRequestDetails.value = JSON.parse(
          learningPathDetails.value.learning_path_details
        );
        if (learningPathDetails.value.contact_details) {
          contactDetails.value = JSON.parse(
            learningPathDetails.value.contact_details
          );
        }
        updatedAt.value = response.data.payload.learningPath.updated_at;
        console.log(learningPathRequestDetails.value);
        console.log(learningPathDetails.value);
        markdownSource.value = learningPathDetails.value.response_content;
        setTimeout(() => {
          if (
            typeof document
              .getElementsByClassName("table-responsive")[0]
              .getElementsByTagName("table")[0] !== "undefined"
          ) {
            document
              .getElementsByClassName("table-responsive")[0]
              .getElementsByTagName("table")[0]
              .classList.add("table");
          }
        }, 100);
        // Reset form fields
      } catch (error: any) {
        toast.error(error.response.data.error.message, {
          autoClose: 5000,
          position: "bottom-right",
        });
      }
    };

    return {
      learningPathForm,
      markdownSource,
      results,
      reachOutContainer,
      contactDetails,
      updatedAt,
      learningPathRequestDetails,
    };
  },
});
