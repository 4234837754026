
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import axios from "axios";
import Markdown from "vue3-markdown-it";
import localforage from "localforage";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default defineComponent({
  name: "LearningPathSuggestionsView",
  components: { Markdown },
  setup() {
    const learningPathForm = ref();
    const askForHelpForm = ref();
    const targetPopulation = ref("");
    const contactDetails = reactive({
      name: "",
      company: "",
      email: "",
      phone: "",
    });
    const reachOutVisible = ref(false);
    const skillSet = ref("");
    const currentLevel = ref("");
    const time = ref("");
    const markdownSource = ref("");
    const results = ref<HTMLElement | null>(null);
    const reachOutContainer = ref<HTMLElement | null>(null);
    const createLearningPathLoading = ref(false);
    const askForHelpLoading = ref(false);
    const clickReachOut = () => {
      reachOutVisible.value = true;
      setTimeout(() => {
        window.scrollTo(
          0,
          document.body.scrollHeight || document.documentElement.scrollHeight
        );
      }, 100);
    };
    const callApiCreateLearningPath = async () => {
      markdownSource.value = "";
      try {
        createLearningPathLoading.value = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/openai/learning-path/create `,
          {
            targetPopulation: targetPopulation.value,
            skillSet: skillSet.value,
            currentLevel: currentLevel.value,
            time: time.value,
          }
        );

        console.log("response.", response);
        // Handle successful response
        markdownSource.value = response.data.payload.content;
        localforage
          .setItem("requestUuid", response.data.payload.requestUuid)
          .then(() => {
            console.log(
              "successfully set requestUuid to " +
                response.data.payload.requestUuid
            );
          });

        setTimeout(() => {
          if (
            typeof document
              .getElementsByClassName("table-responsive")[0]
              .getElementsByTagName("table")[0] !== "undefined"
          ) {
            document
              .getElementsByClassName("table-responsive")[0]
              .getElementsByTagName("table")[0]
              .classList.add("table");
          }
        }, 100);
        setTimeout(() => {
          if (results.value) {
            results.value.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 200);

        // Reset form fields
      } catch (error: any) {
        if (error.response.data.error.code === "too.many.attempts.error") {
          toast.error(
            "The LPG currently has a cap of 10 messages every 24 hours. If you need more, please reach out.",
            {
              autoClose: 5000,
              position: "bottom-right",
            }
          );
          return;
        }
        toast.error(error.response.data.error.message, {
          autoClose: 5000,
          position: "bottom-right",
        });
      }
    };
    const callApiAskForHelp = async () => {
      localforage
        .getItem("requestUuid")
        .then((requestUuid) => {
          console.log("Request uuid found: ", requestUuid);
          try {
            askForHelpLoading.value = true;
            const response = axios
              .post(
                `${process.env.VUE_APP_API_ENDPOINT}/openai/learning-path/${requestUuid}/contact `,
                contactDetails
              )
              .then(() => {
                askForHelpLoading.value = false;
                toast.success(
                  "Contact successfully sent ! Somebody will reach you soon",
                  {
                    autoClose: 5000,
                    position: "bottom-right",
                  }
                );
              })
              .catch((error: any) => {
                askForHelpLoading.value = false;
                toast.error(error.response.data.error.message, {
                  autoClose: 5000,
                  position: "bottom-right",
                });
                console.log("error", error);
              });

            // Reset form fields
          } catch (error: any) {
            // Handle error
            console.error("API Error:", error);
            toast.error(error.response.data.error.message, {
              autoClose: 5000,
              position: "bottom-right",
            });
            askForHelpLoading.value = false;
          }
        })
        .catch((error: any) => {
          toast.error("Sorry, something went wrong :(", {
            autoClose: 5000,
            position: "bottom-right",
          });
          console.error("could not retrieve requestUuid", error);
        });
    };
    const submitAskForHelpForm = (event: Event) => {
      if (!askForHelpForm.value.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        askForHelpForm.value.classList.add("was-validated");
      } else {
        event.preventDefault();
        event.stopPropagation();
        callApiAskForHelp().then(() => {
          console.log("done");
          askForHelpLoading.value = false;
        });
      }
    };
    const submitForm = (event: Event) => {
      if (!learningPathForm.value.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        learningPathForm.value.classList.add("was-validated");
      } else {
        event.preventDefault();
        event.stopPropagation();
        callApiCreateLearningPath().then(() => {
          reachOutVisible.value = false;
          createLearningPathLoading.value = false;
        });
      }
    };
    return {
      learningPathForm,
      targetPopulation,
      skillSet,
      currentLevel,
      time,
      markdownSource,
      results,
      createLearningPathLoading,
      reachOutVisible,
      reachOutContainer,
      askForHelpForm,
      askForHelpLoading,
      contactDetails,
      submitForm,
      clickReachOut,
      submitAskForHelpForm,
    };
  },
});
