<template>
  <div style="display: flex; flex-direction: column; flex: 1">
    <nav
      class="navbar"
      style="
        background: rgba(0, 0, 0, 0.9);
        padding-left: 2rem;
        padding-right: 2rem;
        height: 70px;
      "
    >
      <div class="container-fluid">
        <a
          href="https://www.teamlearning.ai/"
          target="_blank"
          class="navbar-brand text-white"
        >
          <img height="40" src="/black-logo.svg" alt="" />
          &nbsp; teamlearn.ai
        </a>
        <a
          href="https://www.teamlearning.ai/get-demo"
          style="color: white; text-decoration: none"
          target="_blank"
          >Contact</a
        >
      </div>
    </nav>
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //font-family: "Barlow", sans-serif;
  //font-family: "Inter", sans-serif;
  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
nav.bg-body-tertiary {
  background: rgb(0, 0, 0, 0);
}
</style>
