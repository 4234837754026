import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// Import Bootstrap styles
import "bootstrap/dist/css/bootstrap.css";
import localforage from "localforage";
localforage.config({
  name: "teamlearn",
  storeName: "store",
});
createApp(App).use(router).mount("#app");
